import { handleActions } from "redux-actions";

const GalleryReducer = {
  photoList: [],
  likePhotoStatus: null,
  photoUploadStatus: null,
  commentStatus: null,
  commentData: [],
  eligibleCount: 0,
  setAsProfilePicStatus: null,
  picVisibilityChangeStatus: null,
  picDeleteStatus: null,
  likeForCommentStatus: null,
  galleryUnlockedStatus: null,
  shareToChatReqData: {},
  userCoins: 0,
  chatGallery: [],
};

const reducer = handleActions(
  {
    POST_PHOTO_GALLERY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    POST_PHOTO_GALLERY_SUCCESS: (state, action) => {
      return {
        ...state,
        photoUploadStatus: true,
      };
    },
    POST_PHOTO_GALLERY_FAILURE: (state) => {
      return {
        ...state,
        photoUploadStatus: false,
      };
    },
    GET_GALLERY_PHOTOS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_GALLERY_PHOTOS_SUCCESS: (state, action) => {
      return {
        ...state,
        photoList: action.payload.photo,
        eligibleCount:
          action.payload.max_upload_count - action.payload.user_uploaded_count,
        galleryUnlockedStatus: action.payload.gallery_unlocked_status,
      };
    },
    GET_GALLERY_PHOTOS_FAILURE: (state) => {
      return {
        ...state,
      };
    },
    GET_GALLERY_PHOTOS_CHAT_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_GALLERY_PHOTOS_CHAT_SUCCESS: (state, action) => {
      return {
        ...state,
        chatGallery: action.payload.gallery_list,
      };
    },
    GET_GALLERY_PHOTOS_CHAT_FAILURE: (state) => {
      return {
        ...state,
      };
    },
    GALLERY_PHOTO_DETAILS_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GALLERY_PHOTO_DETAILS_SUCCESS: (state, action) => {
      return {
        ...state,
        commentData: action.payload.comments,
        shareToChatReqData: action.payload.chat,
        userCoins: action.payload.user_coins,
      };
    },
    GALLERY_PHOTO_DETAILS_FAILURE: (state) => {
      return {
        ...state,
      };
    },

    LIKE_OTHERS_PHOTO_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    LIKE_OTHERS_PHOTO_SUCCESS: (state, action) => {
      return {
        ...state,
        likePhotoStatus: true,
      };
    },
    LIKE_OTHERS_PHOTO_FAILURE: (state) => {
      return {
        ...state,
        likePhotoStatus: false,
      };
    },
    COMMENT_OTHERS_PHOTO_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    COMMENT_OTHERS_PHOTO_SUCCESS: (state, action) => {
      return {
        ...state,
        commentStatus: true,
      };
    },
    COMMENT_OTHERS_PHOTO_FAILURE: (state, action) => {
      return {
        ...state,
        commentStatus: action.payload.message,
      };
    },
    SET_AS_PROFILE_PICTURE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    SET_AS_PROFILE_PICTURE_SUCCESS: (state, action) => {
      return {
        ...state,
        setAsProfilePicStatus: true,
      };
    },
    SET_AS_PROFILE_PICTURE_FAILURE: (state) => {
      return {
        ...state,
        setAsProfilePicStatus: false,
      };
    },
    CHANGE_PICTURE_VISIBILITY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    CHANGE_PICTURE_VISIBILITY_SUCCESS: (state, action) => {
      return {
        ...state,
        picVisibilityChangeStatus: true,
      };
    },
    CHANGE_PICTURE_VISIBILITY_FAILURE: (state) => {
      return {
        ...state,
        picVisibilityChangeStatus: false,
      };
    },
    DELETE_GALLERY_PICTURE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    DELETE_GALLERY_PICTURE_SUCCESS: (state, action) => {
      return {
        ...state,
        picDeleteStatus: true,
      };
    },
    DELETE_GALLERY_PICTURE_FAILURE: (state) => {
      return {
        ...state,
        picDeleteStatus: false,
      };
    },
    LIKE_FOR_COMMENT_INITIATE: (state) => {
      return {
        ...state,
        likeForCommentStatus: false,
      };
    },
    LIKE_FOR_COMMENT_SUCCESS: (state, action) => {
      return {
        ...state,
        likeForCommentStatus: true,
      };
    },
    LIKE_FOR_COMMENT_FAILURE: (state) => {
      return {
        ...state,
        likeForCommentStatus: false,
      };
    },
    SET_PHOTO_CHANGE_STATUS_NULL: (state) => {
      return {
        ...state,
        setAsProfilePicStatus: null,
        picVisibilityChangeStatus: null,
        picDeleteStatus: null,
        photoUploadStatus: null,
        commentStatus: null,
        likeForCommentStatus: null,
        likePhotoStatus: null,
      };
    },
  },
  GalleryReducer
);
export default reducer;
