import { handleActions } from 'redux-actions';

const CommonReducer = {
  activeUsersList:[],
  newestMemberList:[],
  userDataById:{},
  favoriteStatus:null,
  likeStatus:null,
  inYourAreaMemberList:[],
  footerData:[],
  pageCount:0,
  inYourAreaCount:0,
   tokenStatus:null,
   agbStatus:null,
   postMailStatus:null,
   postOTPStatus:null,
   resendOTPStatus:null,
};

const reducer = handleActions(
  {
    GET_ACTIVE_USER_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    GET_ACTIVE_USER_SUCCESS: (state, action) => {
      return {
        ...state,
        activeUsersList: action.payload,
      };
    },
    GET_ACTIVE_USER_FAILURE: (state) => {
      return {
        ...state,
        activeUsersList: {},
      };
    },

    GET_NEWESTMEMBER_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      GET_NEWESTMEMBER_SUCCESS: (state, action) => {
        return {
          ...state,
          newestMemberList: action.payload.result,
          pageCount:action.payload.RecordTotal
        };
      },
      GET_NEWESTMEMBER_FAILURE: (state) => {
        return {
          ...state,
          newestMemberList: {},
        };
      },
      USER_BY_ID_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      USER_BY_ID_SUCCESS: (state, action) => {
        return {
          ...state,
          userDataById: action.payload,
        };
      },
      USER_BY_ID_FAILURE: (state) => {
        return {
          ...state,
          userDataById: {},
        };
      },
      FAVORITE_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      FAVORITE_SUCCESS: (state, action) => {
        return {
          ...state,
          favoriteStatus: action.payload,
        };
      },
      FAVORITE_FAILURE: (state) => {
        return {
          ...state,
          favoriteStatus: null,
        };
      },
     LIKE_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      LIKE_SUCCESS: (state, action) => {
        return {
          ...state,
          likeStatus:  action.payload,
        };
      },
      LIKE_FAILURE: (state) => {
        return {
          ...state,
          likeStatus: false,
        };
      },
      GET_IN_YOUR_AREA_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      GET_IN_YOUR_AREA_SUCCESS: (state, action) => {
        return {
          ...state,
          inYourAreaMemberList: action.payload.result,
          inYourAreaCount:action.payload.RecordTotal
        };
      },
      GET_IN_YOUR_AREA_FAILURE: (state) => {
        return {
          ...state,
          inYourAreaMemberList: [],
        };
      },
      GET_FOOTER_DATA_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      GET_FOOTER_DATA_SUCCESS: (state, action) => {
        return {
          ...state,
          footerData:[{tot_coins:action.payload.coins,level:action.payload.level,progress:action.payload.progress,recent_chest:action.payload.recent_chest,unread_messages:action.payload.unread_messages}]
        };
      },
      GET_FOOTER_DATA_FAILURE: (state) => {
        return {
          ...state,
          footerData: [],
        };
      },
      VALIDATE_TOKEN_INITIATE: (state) => {
        return {
          ...state,
        };
      },
      VALIDATE_TOKEN_SUCCESS: (state, action) => {
        return {
          ...state,
          tokenStatus:action.payload
        };
      },
      VALIDATE_TOKEN_FAILURE: (state) => {
        return {
          ...state,
          tokenStatus:false
        };
      },
      ACCEPT_AGB_INITIATE: (state) => {
        return {
          ...state,

        };
      },
      ACCEPT_AGB_SUCCESS: (state) => {
        return {
          ...state,
          agbStatus:true
        };
      },
      ACCEPT_AGB_FAILURE: (state) => {
        return {
          ...state,
          agbStatus:false
        };
      },
      POST_EMAIL_INITIATE: (state) => {
        return {
          ...state,

        };
      },
      POST_EMAIL_SUCCESS: (state) => {
        return {
          ...state,
          postMailStatus:true
        };
      },
      POST_EMAIL_FAILURE: (state) => {
        return {
          ...state,
          postMailStatus:false
        };
      },
      POST_OTP_INITIATE: (state) => {
        return {
          ...state,

        };
      },
      POST_OTP_SUCCESS: (state) => {
        return {
          ...state,
          postOTPStatus:true
        };
      },
      POST_OTP_FAILURE: (state) => {
        return {
          ...state,
          postOTPStatus:false
        };
      },
      RESEND_OTP_INITIATE: (state) => {
        return {
          ...state,

        };
      },
      RESEND_OTP_SUCCESS: (state) => {
        return {
          ...state,
          resendOTPStatus:true
        };
      },
      RESEND_OTP_FAILURE: (state) => {
        return {
          ...state,
          resendOTPStatus:false
        };
      },
      SET_COMMON_REDUCER_NULL: (state) => {
        return {
          ...state,
          likeStatus:null,
          inYourAreaMemberList:[],
          activeUsersList:[],
          newestMemberList:[],
          userDataById:{},
          favoriteStatus:null,
          resendOTPStatus:null,
        };
      },
  },
  CommonReducer
);
export default reducer;
