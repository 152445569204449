import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

let lang = localStorage.getItem("lang");
lang = lang === undefined || lang === "" || lang === null ? "gr" : lang;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "gr",
    fallbackLng: "gr",
    debug: false,
    ns: ["i18n"],
    defaultNS: "i18n",
    nsSeparator: false,
    backend: { loadPath: "assets/messages/{{lng}}.json" },
    interpolation: { escapeValue: false },
    keySeparator: false,
    react: { wait: true, useSuspense: false },
  });

export default i18n;
